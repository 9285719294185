import axios from "axios";
import { ngrok_url, payment_url } from "../bootapi";

export const fetchNewsletterByEmail = (email, token) => {
  return axios.get(`${payment_url}/subsapp/newslatter?email=${email}`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const subscribeToNewsletter = (data, token) => {
  return axios.post(`${payment_url}/subsapp/newslatter`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const createRatings = (token, data) => {
  return axios.post(
    `${payment_url}/subsapp/story_rating`, data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAllAudioStories = (token) => {
  return axios.get(
    `${payment_url}/subsapp/story_audio`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAllFavouriteAudioStories = (token, userId) => {
  return axios.get(
    `${payment_url}/subsapp/favourites?user_id=${userId}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSingleFavouriteAudioStories = (token, userId, storyId) => {
  return axios.get(
    `${payment_url}/subsapp/favourites?user_id=${userId}&story_id=${storyId}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const createFavouriteAudioStories = (token, data) => {
  return axios.post(
    `${payment_url}/subsapp/favourites`, data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteFavouriteAudioStories = (user_id, id, token) => {
  return axios.delete(
    `${payment_url}/subsapp/favourites?user_id=${user_id}&story_id=${id}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};

export const getAllRecentlyPlayedAudioStories = (token, userId) => {
  return axios.get(
    `${payment_url}/subsapp/recently_played?user_id=${userId}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const createRecentlyPlayed = (token, data) => {
  return axios.post(
    `${payment_url}/subsapp/recently_played`, data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateRecentlyPlayed = (token, userId, story_id, data) => {
  return axios.put(
    `${payment_url}/subsapp/recently_played?user_id=${userId}&story_id=${story_id}`, data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAllAuthor = (token) => {
  return axios.get(
    `${payment_url}/subsapp/audio_author`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAcessToken = (token) => {
  return axios.get(`${payment_url}/subsapp/access_token`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getLoginAuditLog = (token, data) => {
  return axios.post(`${payment_url}/subsapp/login_audit_log`, data, {
    headers: { Authorization: `${token}` },
  });
};

export const getAppConf = (val) => {
  return axios.get(`${payment_url}/subsapp/app_conf_value?key_name=${val}`);
};

export const getMultipleAppConf = () => {
  const data = {
    "keys": ["footer_email", "footer_number", "free_trial", "site_key", "is_freemium", "is_credit", "free_credit_limit",]
  }
  return axios.post(`${payment_url}/subsapp/app_confing_key`, data);
};

export const seventhplans = () => {
  return axios.get(`${payment_url}/subsapp/subscription_plan`);
};
export const activeSubscription = (id, token) => {
  return axios.get(
    `${payment_url}/subsapp/subscription_detail?user_id=${id}&is_active=True`,
    {
      headers: { Authorization: `${token}` },
    }
  );
};

export const getallsubscriptiondetail = (id, token) => {
  return axios.get(`${payment_url}/subsapp/subscription_detail?user_id=${id}`, {
    headers: { Authorization: `${token}` },
  });
};
export const createSubscription = (formData, token) => {
  return axios.post(`${payment_url}/subsapp/subscription_create`, formData, {
    headers: { Authorization: `${token}` },
  });
};

export const createStripeSubscription = (formData, token) => {
  return axios.post(
    `${payment_url}/subsapp/subscription_create_intent`,
    formData,
    {
      headers: { Authorization: `${token}` },
    }
  );
};

export const updateStripePayment = (formData, token) => {
  return axios.post(`${payment_url}/subsapp/change_card_intent`, formData, {
    headers: { Authorization: `${token}` },
  });
};
export const updateStripePaymentId = (formData, token) => {
  return axios.put(`${payment_url}/subsapp/change_card_intent`, formData, {
    headers: { Authorization: `${token}` },
  });
};
export const getTransactionHistory = (user_Id, token) => {
  return axios.get(`${payment_url}/subsapp/customer_history?user=${user_Id}`, {
    headers: { Authorization: `${token}` },
  });
};
export const getcardDetails = (id, token) => {
  return axios.get(`${payment_url}/subsapp/card_details?user_id=${id}`, {
    headers: { Authorization: `${token}` },
  });
};
//this is an old api
export const couponCheck = (coupon, subscription) => {
  return axios.get(
    `${payment_url}/subsapp/coupon_check?coupon=${coupon}&subscription=${subscription}`
  );
};
export const couponCheckNew = (formData) => {
  return axios.post(`${payment_url}/subsapp/coupon_check`, formData);
};
export const giftCouponCheck = (data) => {
  return axios.post(`${payment_url}/subsapp/gift_check`, data);
};
export const updatePlan = (body, token) => {
  return axios.post(`${payment_url}/subsapp/update_plan`, body, {
    headers: { Authorization: `${token}` },
  });
};
export const subscriptionCancel = (obj, token) => {
  return axios.post(`${payment_url}/subsapp/subscription_cancel`, obj, {
    headers: { Authorization: `${token}` },
  });
};
export const createbetaUser = (formdata, token) => {
  return axios.post(`${payment_url}/subsapp/beta_user`, formdata, {
    headers: { Authorization: `${token}` },
  });
};
export const sendgiftcard = (formdata, token) => {
  return axios.post(`${payment_url}/subsapp/send_gift_card`, formdata, {
    headers: { Authorization: `${token}` },
  });
};

export const journalpasswordchange = (data, token) => {
  return axios.post(`${payment_url}/subsapp/journal_password_validate`, data, {
    headers: { Authorization: `${token}` },
  });
};
export const journalpasswordCreate = (data, token) => {
  return axios.post(`${payment_url}/subsapp/journal_password_create`, data, {
    headers: { Authorization: `${token}` },
  });
};
export const appreview = (body) => {
  axios.post(`${payment_url}/subsapp/review`, body).then((res) => { });
};

export const registrationMail = (body) => {
  axios.post(`${payment_url}/subsapp/registration`, body).then((res) => { });
};
export const createGiftCard = (formdata) => {
  return axios.post(`${payment_url}/subsapp/send_gift_card`, formdata);
};

export const sendGiftCardSuccess = (data) => {
  return axios.put(`${payment_url}/subsapp/send_gift_card`, data);
};
export const sendGiftCardSuccessNew = (data) => {
  return axios.post(`${payment_url}/subsapp/send_gift_card`, data);
};

export const contactus = (data) => {
  return axios.post(`${payment_url}/subsapp/contact_us`, data);
};

export const getGiftCardDetails = (eventId) => {
  return axios.get(`${payment_url}/subsapp/send_gift_card?event_id=${eventId}`);
};

export const getGiftCardSuccessDetails = (eventId) => {
  return axios.get(`${payment_url}/subsapp/gift_card?event_id=${eventId}`);
};

export const getGiftDetailsHistory = (data, token) => {
  return axios.post(`${payment_url}/subsapp/gift_card_details`, data, {
    headers: { Authorization: `${token}` },
  });
};
export const getSubscriptionById = (sub_id, user_id, token) => {
  return axios.get(
    `${payment_url}/subsapp/subscription_detail?subscription_id=${sub_id}&user_id=${user_id}`,
    {
      headers: { Authorization: `${token}` },
    }
  );
};
export const getPendingPayment = (customer_id, user_id, token) => {
  return axios.get(
    `${payment_url}/subsapp/incomplete?user=${user_id}&customer_id=${customer_id}`,
    {
      headers: { Authorization: `${token}` },
    }
  );
};
export const getThreeDIncomplete = (data, token) => {
  return axios.post(`${payment_url}/subsapp/incomplete`, data, {
    headers: { Authorization: `${token}` },
  });
};
export const getAfterSuccessThreeD = (data, token) => {
  return axios.put(`${payment_url}/subsapp/incomplete`, data, {
    headers: { Authorization: `${token}` },
  });
};
export const unsubscribeGuest = (email) => {
  return axios.get(`${payment_url}/subsapp/guest_unsubscribe?email=${email}`);
};
export const unsubscribeNewsLetter = (email) => {
  return axios.get(
    `${payment_url}/subsapp/newslatter_unsubscribe?email=${email}`
  );
};
export const sendReview = (data, token) => {
  return axios.post(`${payment_url}/subsapp/send_review`, data, {
    headers: { Authorization: `${token}` },
  });
};
export const getReviewList = (type) => {
  return axios.get(
    `${payment_url}/subsapp/users_review?publish=True&site=${type}`
  );
};

export const dataDeleteRequest = (data, token) => {
  return axios.post(`${payment_url}/subsapp/data_delete_request`, data, {
    headers: { Authorization: `${token}` },
  });
};

export const reactivateSubscription = (data, token) => {
  return axios.post(`${payment_url}/subsapp/reactivate`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const masterLoginApi = (data, token) => {
  return axios.post(`${payment_url}/subsapp/crm_user_login`, data, {
    headers: { Authorization: token, "Content-Type": "application/json" },
  });
};

export const saveDream = (data, token) => {
  return axios.post(`${payment_url}/subsapp/save_dream_while_sleeping`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSavedDreams = (userId, token) => {
  return axios.get(
    `${payment_url}/subsapp/save_dream_while_sleeping?user_id=${userId}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteDream = (userId, dreamId, token) => {
  return axios.delete(
    `${payment_url}/subsapp/save_dream_while_sleeping?user_id=${userId}&id=${dreamId}`,

    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const createUser = (userData, token) => {
  return axios.post(`${payment_url}/subsapp/subs_users`, userData, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getUserDetails = (userId, token) => {
  return axios.get(`${payment_url}/subsapp/subs_users?user_id=${userId}`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateUserDetails = (userId, id, data, token) => {
  return axios.put(
    `${payment_url}/subsapp/subs_users?user_id=${userId}&id=${id}`,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const verifyUser = (email, token) => {
  return axios.get(
    `${payment_url}/subsapp/subs_users?email=${email.toLowerCase()}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteUser = (userId, token) => {
  return axios.delete(`${payment_url}/subsapp/subs_users?user_id=${userId}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const createDeviceData = (data, token) => {
  return axios.post(`${payment_url}/subsapp/subs_user_device_ids`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getUserDeviceIds = (userId, token) => {
  return axios.get(
    `${payment_url}/subsapp/subs_user_device_ids?user_id=${userId}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateUserDeviceId = (userId, id, data, token) => {
  return axios.put(
    `${payment_url}/subsapp/subs_user_device_ids?user_id=${userId}&id=${id}`,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const createTempSubscription = (data, token) => {
  return axios.post(`${payment_url}/subsapp/subs_temsubscription`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteTempSubscription = (uniqueKey, token) => {
  return axios.delete(
    `${payment_url}/subsapp/subs_temsubscription?unique_key=${uniqueKey}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};

export const getTempSubscription = (uniqueKey, token) => {
  return axios.get(
    `${payment_url}/subsapp/subs_temsubscription?unique_key=${uniqueKey}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};

export const sendNotification = (data, token) => {
  return axios.post(`${payment_url}/subsapp/send_notification`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getAdditionalDetails = (userId, token) => {
  return axios.get(
    `${payment_url}/subsapp/subs_additionaldetails?user_id=${userId}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};

export const updateAdditionalDetails = (userId, id, data, token) => {
  return axios.put(
    `${payment_url}/subsapp/subs_additionaldetails?user_id=${userId}&id=${id}`,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getDataSubjectAccess = (email, token) => {
  return axios.get(
    `${payment_url}/subsapp/data_subject_access?email=${email}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const createDataSubjectAccessRequest = (data, token) => {
  return axios.post(`${payment_url}/subsapp/data_subject_access`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getAudioStories = (token) => {
  return axios.get(`${payment_url}/subsapp/story_audio`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getUserHabit = (user_id, token) => {
  return axios.get(`${payment_url}/subsapp/user_habit?user_id=${user_id}`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const createUserHabit = (formData, token) => {
  return axios.post(`${payment_url}/subsapp/user_habit`, formData, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateUserHabit = (user_id, id, data, token) => {
  return axios.put(
    `${payment_url}/subsapp/user_habit?id=${id}&user_id=${user_id}`,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteUserHabit = (id, userId, token) => {
  return axios.delete(
    `${payment_url}/subsapp/user_habit?id=${id}&user_id=${userId}`,
    {
      headers: { Authorization: `${token}` },
    }
  );
};

export const getUserHabitLogs = (userId, token) => {
  return axios.get(`${payment_url}/subsapp/user_habit_log?user_id=${userId}`, {
    headers: { Authorization: `${token}` },
  });
};

export const createUserHabitLog = (data, token) => {
  return axios.post(`${payment_url}/subsapp/user_habit_log`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteUserHabitLog = (id, userId, token) => {
  return axios.delete(
    `${payment_url}/subsapp/user_habit_log?id=${id}&user_id=${userId}`,
    {
      headers: { Authorization: `${token}` },
    }
  );
};

export const updateUserHabitLog = (id, user_id, data, token) => {
  return axios.put(
    `${payment_url}/subsapp/user_habit_log?id=${id}&user_id=${user_id}`,
    data,
    {
      headers: { Authorization: `${token}` },

      "Content-Type": "application/json",
    }
  );
};



export const getUserGoals = (userId, token) => {
  return axios.get(`${payment_url}/subsapp/user_goals?user_id=${userId}`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const createtUserGoals = (formData, token) => {
  return axios.post(`${payment_url}/subsapp/user_goals`, formData, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateUserGoals = (id, userId, data, token) => {
  return axios.put(
    `${payment_url}/subsapp/user_goals?id=${id}&user_id=${userId}`,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteUserGoal = (id, userId, token) => {
  return axios.delete(
    `${payment_url}/subsapp/user_goals?id=${id}&user_id=${userId}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};

export const getUserJournalPassword = (userId, token) => {
  return axios.get(
    `${payment_url}/subsapp/journal_password_create?user_id=${userId}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};

export const getSightSettings = (userId, token) => {
  return axios.get(`${payment_url}/subsapp/sight_settings?user_id=${userId}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const addSightSettings = (data, token) => {
  return axios.post(`${payment_url}/subsapp/sight_settings`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateSightSettings = (id, userId, data, token) => {
  return axios.put(
    `${payment_url}/subsapp/sight_settings?user_id=${userId}&id=${id}`,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteDreamsData = (data, token) => {
  return axios.post(`${ngrok_url}/sight/delete_DreamsData`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const updateDreamsData = (data, token) => {
  return axios.post(`${ngrok_url}/sight/update_DreamsData`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const insertDreamsData = (data, token) => {
  return axios.post(`${ngrok_url}/sight/insert_DreamsData`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const getDreamsData = (data, token) => {
  return axios.post(`${ngrok_url}/sight/get_DreamsData`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateDreamDetails = (data, token) => {
  return axios.post(`${ngrok_url}/sight/update_dream_details`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const getDreamDetails = (data, token) => {
  return axios.post(`${ngrok_url}/sight/get_dream_details`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

export const insertDreamDetails = (data, token) => {
  return axios.post(`${ngrok_url}/sight/insert_dream_details`, data, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
