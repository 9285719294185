import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./header-footer.css";
import { supabase } from "../index";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../reusedElements/NewLoader";
import { toast } from "react-toastify";
import { createNotificationDetails } from "../services/sightdreamService";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Form,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import {
  getNewJournalSettings,
  insertNewJournalSettings,
} from "../services/journaldreamService";
import {
  ACCOUNT_DELETE_REQUEST,
  CheckPathTheme,
  INACTIVE_USER,
  NewFormatDateToCustomString,
  checkPathType,
  getFCMToken,
  siteMapping,
} from "../commonutilities";

import {
  createUser,
  dataDeleteRequest,
  getAcessToken,
  getGiftCardSuccessDetails,
  getUserDetails,
  getUserDeviceIds,
  getallsubscriptiondetail,
  sendNotification,
  sendReview,
  updateUserDeviceId,
  verifyUser,
} from "../services/paymentService";
import ChatLoader from "../reusedElements/chatloader/ChatLoader";

import { callQueryAnswer } from "../services/chatService";
import { showSeventhArc, website_url } from "../bootapi";
import { useFormik } from "formik";
import {
  addReviewValidationSchema,
  deleteValidationSchema,
  signUpValidationSchema,
} from "../ValidationSchema/Schema";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { saveGuestDataToPrev } from "../services/sightdreamService";

import InfoIcon from "@mui/icons-material/Info";
import ReactStars from "react-rating-stars-component";
import Lottie from "lottie-react";
import LottieData from "./../shared/Animation/SignupAnimation.json";
import { toggleTheme, setToken } from "../Features/CommonSlice";
import ThemeSetter from "../ThemeSetter/ThemeSetter";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { geoLocation } from "../services/sightdreamService";
import { getLoginAuditLog } from "../services/paymentService";

const toastobj = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};
const createChatLi = (message, className) => {
  // console.log("Message:", message, " Classname:", className);
  const chatLi = document.createElement("li");
  chatLi.classList.add(`${className}`);
  if (className == "chatbox-chats-left-support") {
    let chatContent = `<p class="chatbox-chats-left-content-support">${message}</p>`;
    chatLi.innerHTML = chatContent;
    return chatLi;
  } else {
    let chatContent = `<p class="chatbox-chats-right-content-support">${message}</p>`;
    chatLi.innerHTML = chatContent;
    return chatLi;
  }
};

const themeConfig = {
  //for SeventhJOURNAL
  1: {
    logo: {
      light:
        "https://db.seventhsight.com/storage/v1/object/public/Website_Images/new-seventhjournal-logo-white.png",
      dark: "https://db.seventhsight.com/storage/v1/object/public/Website_Images/new-seventhjournal-logo-black.png",
    },
    backgroundClass: {
      light: "journal-bg-white",
      dark: "journal-bg-dark",
    },
    scrollNavClass: {
      true: "main-header dark-header journal",
      false: "main-header dark-header journal",
    },
    onLogoClick: "seventhjournal",
    navTogglerTheme: {
      open: {
        dark: "menubar-open-journal-dark",
        light: "menubar-open-journal",
      },
      close: {
        dark: "menubar-close-journal-dark",
        light: "menubar-close-journal",
      },
    },
  },
  //for SeventhArc
  2: {
    logo: "https://db.seventhsight.com/storage/v1/object/public/Website_Images/seventharc_logo.png?t=2024-01-10T07%3A19%3A20.784Z",
    backgroundClass: "bg-white",
    scrollNavClass: {
      true: "main-header bg-light main-header-seventArc dark-header",
      false: "main-header main-header-seventArc dark-header",
    },
    onLogoClick: "seventharc",
  },
  //for SeventhSIGHT
  3: {
    logo: "https://db.seventhsight.com/storage/v1/object/public/Website_Images/logo.png",
    backgroundClass: "bg-white",
    scrollNavClass: {
      true: "main-header",
      false: "main-header",
    },
    onLogoClick: "",
  },
  default: {
    navTogglerTheme: {
      open: "menubar-open",
      close: "menubar-close",
    },
  },
};

function Header({
  loginmodalhome,
  setloginmodalhome,
  fromwhere,
  setshowtimejournal,
  togglefeedback,
  toggleNewSignIn,
  successGiftCard,
  toggleMenu,
  setsignupmodalhome,
  signupmodalhome,
  menuOpen,
  newBetaUser,
  supportChat,
  captchaKey,
  reviewOpen,
  setReviewOpen,
  deleteRequest,
  noFreeCredit,
  logout,
  setlogout,
  darkModeFromJournal,
}) {
  const newToken = useSelector((state) => state.theme.token);
  let flag = false;
  const chatboxRef = useRef();
  const history = useNavigate();
  const chatInputRef = useRef();
  const [lemail, setEmail] = useState("");
  const [scroll, setScroll] = useState(false);
  const [lpassword, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [spassword, setsPassword] = useState("");
  const [newPassword, setNewPassword] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [showname, setname] = useState("My Account");
  const [modalLogin, setModalLogin] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  const [modalSignup, setModalSignup] = useState(false);
  const [modalNewPass, setModalNewPass] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newSignInModal, setNewSignInModal] = useState(false);
  const [sconfirmpassword, setsConfirmPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(flag);
  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const [modalForgotPass, setModalForgotPass] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [modalPasswordSet, setModalPasswordSet] = useState(false);
  const [resendVisible, setResendVisible] = useState(false);
  // const [subscriptionFound, setSubscriptionNotFound] = useState(true);
  const [giftCardSuccessModal, setGiftCardSuccessModal] = useState(false);
  const [senderData, setSenderData] = useState();
  const [timer, setTimer] = useState(60);

  const currentPath = window.location.href;
  // const darkMode = useSelector((state) => state.theme.darkMode);
  const [darkMode, setDarkMode] = useState(true);
  const [comingSoonModal, setComingSoonModal] = useState(false);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const redirectPath = params.get("redirect");
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [subType, setSubType] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  //validation for signup
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      confirmpassword: "",
      recaptcha: "",
      newsLetter: true,
    },
    validationSchema: signUpValidationSchema,
    onSubmit: (values, action) => {
      // Handle your form submission here
      // console.log(values);
      // action.resetForm();
      handleSignupSubmit();
    },
  });
  const {
    values: valuesReview,
    errors: errosReview,
    handleChange: handleChangeReview,
    handleSubmit: handleSubmitNew,
    handleBlur: handleBlurReview,
    touched: touchedReview,
    resetForm: resetFormReview,
    setFieldValue: setFieldValueReview,
  } = useFormik({
    initialValues: {
      rating: 0,
      username: "",
      review: "",
      recaptcha: "",
    },
    validationSchema: addReviewValidationSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      handleSubmitReview(values);
    },
  });
  const {
    values: valuesDelete,
    errors: errorsDelete,
    handleChange: handleChangeDelete,
    handleSubmit: handleSubDelete,
    handleBlur: handleBlurDelete,
    touched: touchedDelete,
    resetForm: resetFormDelete,
    setFieldValue: setFieldValueDelete,
  } = useFormik({
    initialValues: {
      username: "",
      email: "",
      recaptcha: "",
    },
    validationSchema: deleteValidationSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      // handleDeleteAccount(values);
      setDeleteModal(!deleteModal);
      toggleDeleteWarningModal();
    },
  });

  //new modals for flow
  const [showForgotPassEmailPopup, setShowForgotPassEmailPopup] =
    useState(false);
  const [emailAlreadyRegisteredModal, setEmailAlreadyRegisteredModal] =
    useState(false);

  //ends here
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleDeleteAccount = () => {
    if (!deleteModal) {
      let obj = JSON.parse(localStorage.getItem("sevensight_user"));
      if (obj) {
        setFieldValueDelete("email", obj["email"]);
      }
    }
    setDeleteModal(!deleteModal);
    if (modalLogin) {
      setModalLogin(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && modalSignup) {
      const timer = setTimeout(() => {
        toggleSignup();
      }, 1000); // Set the timer to 1 second (1000 milliseconds)

      return () => clearTimeout(timer); // Clear the timer when the component unmounts
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (typeof darkModeFromJournal == "boolean") {
      CheckPathReturnTheme();
    }
  }, [darkModeFromJournal]);

  useEffect(() => {
    if (fromwhere === "privacy-policy") {
      setScroll(true);
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
  }, [fromwhere]);
  const dispatch = useDispatch();

  useEffect(() => {
    checkAuthentication();
    getIPAddress();
  }, []);
  const getIPAddress = () => {
    geoLocation()
      .then((jres) => {
        localStorage.setItem("ip_address", jres?.data?.ip)
      })
      .catch((err) => {
        console.log("376-->", err);
      })
  }
  const ipAddress = localStorage.getItem("ip_address");

  const CheckPathReturnTheme = () => {
    let obj = CheckPathTheme();
    setDarkMode(obj?.darkMode);
    dispatch(toggleTheme(obj?.theme));
  };

  useEffect(() => {
    setSubType(checkPathType());
    CheckPathReturnTheme();
  }, [useLocation().pathname]);

  useEffect(() => {
    if (loginmodalhome && !isAuthenticated) toggleLogin();
  }, [loginmodalhome]);

  useEffect(() => {
    if (signupmodalhome && !isAuthenticated) toggleSignup();
  }, [signupmodalhome]);
  useEffect(() => {
    // console.log(supportChat);
    if (supportChat && !supportChatOpen) toggleSupportChat();
  }, [supportChat]);
  useEffect(() => {
    if (logout === true) {
      handleLogout();
    }
  }, [logout]);
  useEffect(() => {
    const isFeedbackPath = currentPath.includes("/feedback");
    if (isFeedbackPath) {
      togglefeedback();
    }
  }, []);

  const checkAuthentication = async () => {
    const session = await supabase.auth.getSession();

    if (session.data.session !== null) {
      if (setshowtimejournal) setshowtimejournal(true);
      flag = true;
      let obj = JSON.parse(localStorage.getItem("sevensight_user"));
      if (obj) {
        if (obj["user_name"]) {
          setname(obj["user_name"]);
        } else {
          setname(obj["email"]);
        }
      }
    } else {
      if (setshowtimejournal) setshowtimejournal(false);
      localStorage.removeItem("sevensight_user");
    }
    setIsAuthenticated(flag);
  };

  const toggleLogin = () => {
    setEmail("");
    setPassword("");
    setModalLogin(!modalLogin);
    setShowPassword(false);
    setShowConfirmPassword(false);
    if (loginmodalhome) setloginmodalhome(false);
  };

  const toggleSignup = () => {
    setShowPassword(false);
    setShowConfirmPassword(false);
    resetForm();
    setModalSignup(!modalSignup);
    if (signupmodalhome) setsignupmodalhome(false);
  };
  //new toggle
  const toggleForgotPass = () => {
    setModalLogin(false);
    setEmail("");
    setsPassword("");
    setsConfirmPassword("");
    setModalForgotPass(!modalForgotPass);
  };
  const toggleShowForgotPassEmailPopup = () => {
    // toggleForgotPass();
    if (showForgotPassEmailPopup) {
      setResendVisible(false);
      setTimer(60);
    }

    setShowForgotPassEmailPopup(!showForgotPassEmailPopup);
  };
  const toggleNewPass = () => {
    setModalNewPass(!modalNewPass);
    setNewPassword("");
    setConfirmNewPassword("");
  };
  const togglePasswordSet = () => {
    setModalPasswordSet(!modalPasswordSet);
  };
  const toggleModalSignupSentMail = () => {
    setShowPopup(!showPopup);
  };
  const toggleEmailAlreadyRegistered = () => {
    setEmailAlreadyRegisteredModal(!emailAlreadyRegisteredModal);
  };
  const toggleNewSignInModal = () => {
    if (newSignInModal) {
      if (newBetaUser) {
        history("/auth/additionalDetails");
      } else {
        history("/create_subscription");
      }
    }
    setNewSignInModal(!newSignInModal);
  };
  const toggleSuccessfullGiftCardModal = () => {
    setGiftCardSuccessModal(!giftCardSuccessModal);
  };
  const toggleSupportChat = () => {
    setIsOpen(false);
    setSupportChatOpen(!supportChatOpen);
  };
  const toggleDeleteWarningModal = () => {
    setDeleteWarningModal(!deleteWarningModal);
  };

  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);

  const handleSubmitLogin = (event) => {
    setLoading(true);
    event.preventDefault();

    supabase.auth
      .signInWithPassword({
        email: lemail,
        password: lpassword,
      })
      .then((res) => {
        if (res.error === null) {
          let ip_address = localStorage.getItem("ip_address");

          getAcessToken(res.data.session.access_token).then((resp) => {
            if (resp?.data?.status) {
              dispatch(setToken(resp?.data?.auth_token));
            }
            getUserDetails(
              res.data?.session?.user.id,
              resp?.data?.auth_token
            ).then((response) => {
              if (
                response.data.status === true &&
                response.data.status_code === 200
              ) {
                if (response.data.status_code === 200) {
                  let obj = { ...res.data.user };
                  obj["token"] = res.data?.session?.access_token;
                  if (response.data?.user?.name) {
                    obj["user_name"] = response.data?.user?.name;
                  } else {
                    obj["user_name"] = "My Account";
                  }
                  getFCMToken(resp?.data?.auth_token);
                  if (response.data.user.user_status) {
                    setname(obj["user_name"]);
                    localStorage.setItem(
                      "sevensight_user",
                      JSON.stringify(obj)
                    );
                    const data = {
                      user_id: res.data.user["id"],
                      email: lemail,
                      ip_address: ip_address,
                      action: {
                        info: "Logged in Successfully"
                      },
                      created_at: new Date(),
                      updated_at: new Date(),
                      audit_type: "login",
                    }
                    getLoginAuditLog(resp?.data?.auth_token, data)
                      .then((res) => {
                      })
                      .catch((err) => {
                      })
                    //default settings of seventh journal for new users
                    getNewJournalSettings(res.data.user["id"], resp?.data?.auth_token).then((response) => {

                      if (response?.data?.status_code === 200) {
                        // console.log("531------>", "coming here");
                        let theme = {
                          seventhsight: true,
                          seventharc: false,
                          seventhjournal: response?.data?.JournalSettings?.night_mode,
                        };
                        dispatch(toggleTheme(theme));
                      }
                      CheckPathReturnTheme();
                    }).catch((err) => {
                      // console.log("541------>", "coming in error");
                      setLoading(true);
                      let temp = {
                        user_id: res.data.user["id"],
                        back_up_this_device: false,
                        back_up_over_mobiledata: false,
                        back_up_over_cloud: false,
                        sync_all_device: false,
                        night_mode: false,
                        location: false,
                        prompt_notification: false,
                        reminder: false,
                        gratitude: true,
                        goals: false,
                        password_enabled: false,
                        auto_lock_enabled: false,
                        auto_lock_timer: "",
                        created_date: NewFormatDateToCustomString(
                          new Date()
                        ),
                        updated_date: NewFormatDateToCustomString(
                          new Date()
                        ),
                      };

                      insertNewJournalSettings(temp, resp?.data?.auth_token).then((response) => {
                        setLoading(false);
                      }).catch((err) => {
                        toast.error("Somthing went wrong!", toastobj);
                        setLoading(false);
                      })
                    })
                    getallsubscriptiondetail(res.data?.session?.user.id, resp?.data?.auth_token)
                      .then((res) => { })
                      .catch((err) => {
                        // console.log(err);
                        if (err.response.data.status == false) {
                          if (!deleteRequest) {
                            history("/create_subscription", {
                              state: { revOpen: true },
                            });
                          }
                        }
                      });
                    if (reviewOpen) {
                      toggleReviewModal();
                    }
                    setTimeout(() => {
                      toggleLogin();
                      afterLoginDirect(false, res.data?.session?.refresh_token);
                    }, 1000);
                    setIsAuthenticated(true);
                  } else {
                    toast.error(`${INACTIVE_USER}`, toastobj);
                    handleLogout();
                  }
                } else {
                  toast.error("Error! User not Registered");
                }
              } /* else {
                  localStorage.setItem(
                    "sevensight_user",
                    JSON.stringify(res.data.user)
                  );
                } */
            });
            setLoading(false);
          });
        } else {
          setLoading(false);
          toast.error(`Error! ${res.error.message}`, toastobj);
        }
      });
  };

  const handleSignupSubmit = async (event) => {
    setLoading(true);
    try {
      const response = await verifyUser(values.email, newToken);
      const veruser = response.data.user;
      if (veruser) {
        // toast.error('Error! User Already Exist', toastobj);
        toggleSignup();
        toggleEmailAlreadyRegistered();
        resetForm();
        setLoading(false);
        return;
      }
    } catch (err) {
      setShowPopup(true);
      setLoading(true);
      const { user, error } = supabase.auth
        .signUp({
          email: values.email.toLowerCase(),
          password: values.password,
          options: {
            data: {
              name: values.username,
            },
            emailRedirectTo: `${website_url}/auth/new-user`,
            // emailRedirectTo: `http://localhost:3000/auth/new-user`,
          },
        })
        .then((response) => {
          if (response.error) {
            setLoading(false);
          } else {
            const { user, data } = response;
            const { id, email, updated_at, created_at } = data.user;
            const { name } = data.user.user_metadata;

            const formdata = {
              user_id: id,
              name: name,
              email: email.toLowerCase(),
              created_at: created_at,
              updated_date: updated_at,
              guest_credits: noFreeCredit,
              news_latter_unsubscribed:
                typeof values?.newsLetter === "boolean"
                  ? values.newsLetter
                  : true,
            };
            createUser(formdata, newToken).then((res) => {
              if (res.data.status) {
                const notificationData = {
                  user: values.email.toLowerCase(),
                  preference: true,
                };
                if (newToken !== null) {
                  createNotificationDetails(newToken, notificationData)
                    .then((newres) => { })
                    .catch((error) => { });
                  ;
                }
              }
            });
            setLoading(false);
            let tempSaveGuestData = { email: email.toLowerCase(), user_id: id };
            saveGuestDataToPrev(JSON.stringify(tempSaveGuestData)).then(
              () => { }
            );
          }
        })
        .catch((error) => {
          setLoading(false);
        });

      if (error) {
        toast.error(`Error! ${error.message}`, toastobj);
        setLoading(false);
        return;
      }
      toggleSignup();
    }
  };
  const handleSubmitReview = (data) => {
    setLoading(true);
    let obj = JSON.parse(localStorage.getItem("sevensight_user"));

    let formData = new FormData();
    formData.append("name", data.username);
    formData.append("user_id", obj["id"]);
    formData.append("publish", false);
    formData.append("body", data.review);
    formData.append("ratings", data.rating);

    const site = siteMapping[subType];
    if (site) {
      formData.append("site", site);
    }
    sendReview(formData, newToken)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          toast.success(`${res?.data?.message}`, toastobj);
          resetFormReview();
          toggleReviewModal();
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Somthing went wrong!", toastobj);
        toggleReviewModal();
      });
  };
  const handleSubmitForgotPass = async (event) => {
    // toggleForgotPass();
    event.preventDefault();

    if (!lemail) {
      toast.error("Error! Enter details to continue", toastobj);
      return;
    }

    try {
      await verifyUser(lemail, newToken);
    } catch (error) {
      toast.error("Error! Email doesn't exist", toastobj);
      return;
    }
    setLoading(true);

    const { error } = await supabase.auth
      .resetPasswordForEmail(lemail, {
        // redirectTo: "http://localhost:3000/",
        redirectTo: `${website_url}`,
      })
      .then((response) => {
        if (response.error) {
          setLoading(false);
          // console.log(response);
          toast.error(`Error! ${response.error.message}`, toastobj);
        } else {
          setModalForgotPass(false);
          setLoading(false);
          setShowForgotPassEmailPopup(true);
          setResendVisible(false);
          setTimer(60);
          setTimeout(() => {
            setResendVisible(true);
          }, 60000);
        }
      })
      .catch((error) => {
        // Handle any errors related to the signUp function itself
        setLoading(false);
        toast.error(`Error! ${error.message}`, toastobj);
      });
    if (error) {
      setLoading(false);
      toast.error(`Error! ${error.message}`, toastobj);
      return;
    }
  };
  useEffect(() => {
    let interval;
    if (!resendVisible && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      clearInterval(interval);
      setResendVisible(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [resendVisible, timer]);

  const handleLogout = async (event) => {
    event?.preventDefault();
    let obj = JSON.parse(localStorage.getItem("sevensight_user"));
    const data = {
      user_id: obj["id"],
      email: obj["email"],
      ip_address: ipAddress,
      action: {
        info: "Logged Out Successfully"
      },
      created_at: new Date(),
      updated_at: new Date(),
      audit_type: "Logout",
    }
    getLoginAuditLog(newToken, data)
      .then((res) => {
      })
      .catch((err) => {
      })
    const eraseDataLogout = () => {
      supabase.auth.signOut().then((res) => {
        setIsAuthenticated(false);
        localStorage.removeItem("sevensight_user");

        if (res?.error !== null) {
          sessionStorage.clear();
          localStorage.clear();
        }
        let theme = {
          seventhsight: true,
          seventharc: false,
          seventhjournal: false,
        };

        dispatch(toggleTheme(theme));
        CheckPathReturnTheme();
        afterLoginDirect(true);
      });
    };
    const session = await supabase.auth.getSession();
    if (session.data.session !== null) {
      try {
        const response = await getUserDeviceIds(
          session?.data?.session?.user?.id,
          newToken
        );

        const deviceId = response.data.DeviceID.id;
        const updateData = { SeventhSight_web: "" };
        setLoading(true);
        try {
          await updateUserDeviceId(
            session?.data?.session?.user?.id,
            deviceId,
            updateData,
            newToken
          );

          setLoading(false);
          eraseDataLogout();
        } catch (err) {
          setLoading(false);
          eraseDataLogout();
        }
      } catch (error) {
        setLoading(false);
        eraseDataLogout();
      }
    } else {
      eraseDataLogout();
    }
  };
  const handleDeleteAccount = async (values) => {
    toggleDeleteWarningModal();
    setLoading(true);
    let obj = JSON.parse(localStorage.getItem("sevensight_user"));
    let deleteData = {
      email: values.email,
      name: values.username,
      user_id: obj["id"],
    };
    dataDeleteRequest(deleteData, newToken)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          toast.success(
            response?.data?.message
              ? response?.data?.message
              : ACCOUNT_DELETE_REQUEST,
            toastobj
          );
          resetFormDelete();
          handleLogout();
        } else {
          setLoading(false);
          toast.error(
            response?.data?.message
              ? response?.data?.message
              : "Somthing went wrong!",
            toastobj
          );
          resetFormDelete();
        }
      })
      .catch((err) => {
        // toggleDeleteAccount();
        setLoading(false);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Somthing went wrong!",
          toastobj
        );
        resetFormDelete();
      });
  };
  const afterLoginDirect = (type = false, refresh_token) => {
    setIsOpen(false);
    if (!type && redirectPath) {
      const enredirectPath = redirectPath
        ? decodeURIComponent(redirectPath)
        : null;
      const modifiedPath = enredirectPath.includes("/refresh_session/")
        ? enredirectPath.replace("/refresh_session/", `/${refresh_token}/`)
        : enredirectPath;
      history(`/${modifiedPath}`);
      return;
    }
    if (subType == "1") {
      history("/seventhjournal");
    }
    if (subType == "2") {
      history("/seventharc");
    }
    if (subType == "3") {
      history("/");
    }
  };
  const afterOauthLoginDirect = (type = false) => {
    setIsOpen(false);
    if (!type && redirectPath) {
      const enredirectPath = redirectPath
        ? decodeURIComponent(redirectPath)
        : null;

      if (enredirectPath) {
        let data = {
          redirectPath: enredirectPath,
        };
        sessionStorage.setItem("SeventhSightRedirect", JSON.stringify(data));
      }
    }
  };

  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handlegooglesignup = () => {
    afterOauthLoginDirect();
    supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: website_url,
      },
    })
      .then(async (res) => {
        console.log("1003----->", res);
        const { UserInfo } = await supabase.auth.getSession();
        if (UserInfo?.session?.user) {
          const data = {
            user_id: UserInfo.session.user.id,
            email: UserInfo.session.user.email,
            ip_address: ipAddress,
            action: {
              info: "Logged in Successfully"
            },
            created_at: new Date(),
            updated_at: new Date(),
            audit_type: "login",
          }
          getLoginAuditLog(newToken, data)
            .then((res) => {
              console.log("1020---->", res);
            })
            .catch((err) => {
              console.log("1020---->", err);
            })
        }
      });
  };

  const handleapplesignup = () => {
    afterOauthLoginDirect();
    supabase.auth
      .signInWithOAuth({
        provider: "apple",
      })
      .then(async (res) => {
        const { UserInfo } = await supabase.auth.getSession();
        if (UserInfo?.session?.user) {
          const data = {
            user_id: UserInfo.session.user.id,
            email: UserInfo.session.user.email,
            ip_address: ipAddress,
            action: {
              info: "Logged in Successfully"
            },
            created_at: new Date(),
            updated_at: new Date(),
            audit_type: "login",
          }
          getLoginAuditLog(newToken, data)
            .then((res) => {
              console.log("1020---->", res);
            })
            .catch((err) => {
              console.log("1020---->", err);
            })
        }
      });
  };

  const gotopage = (val) => {
    setIsOpen(false);
    history(`/${val}`);
  };
  const resetNewPassword = async (event) => {
    event.preventDefault();

    if (!newPassword) {
      toast.error(`Enter new password`, toastobj);
      return;
    }
    if (newPassword.length < 6) {
      toast.error(`Password should be at least 6 characters`, toastobj);
      return;
    }
    if (!confirmNewPassword) {
      toast.error(`Enter confirm password`, toastobj);
      return;
    }
    if (newPassword !== confirmNewPassword) {
      toast.error(`Passwords don\'t match!`, toastobj);
      return;
    }

    setLoading(true);
    const { error } = await supabase.auth
      .updateUser({
        password: newPassword,
      })
      .then((response) => {
        console.log("994------>", response);
        if (response.error) {
          toast.error(`New password should be different from the old password.`, toastobj); // Use msg here
          setLoading(false);
        } else {
          toggleNewPass();
          setLoading(false);
          togglePasswordSet();
          setTimeout(() => {
            setModalPasswordSet(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`New password should be different from the old password.`, toastobj);
        console.log("1008---->", err);
      });

    if (error) {
      setLoading(false);
      toast.error(`Error! ${error?.message}`, toastobj);
      return;
    }
  };
  useEffect(() => {
    // setLoading(true);

    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        setModalNewPass(true);
      }
    });
    if (toggleNewSignIn) {
      toggleNewSignInModal();
    }
  }, []);

  useEffect(() => {
    if (!toggleMenu) {
      setIsOpen(toggleMenu);
    }
    if (isOpen) {
      setIsOpen(false);
    }
  }, [toggleMenu]);

  let userMessage;
  const handleChat = () => {
    userMessage = chatInputRef.current.value.trim();
    if (!userMessage) return;
    else {
      chatboxRef.current.appendChild(
        createChatLi(userMessage, "chatbox-chats-right-support")
      );
      chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight);
      const formdata = new FormData();
      formdata.append("query", userMessage);

      setChatLoader(true);
      callQueryAnswer(formdata)
        .then((response) => {
          if (response.status == 200) {
            setChatLoader(false);
            chatboxRef.current.appendChild(
              createChatLi(response.data.answer, "chatbox-chats-left-support")
            );
            chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight);
          }
          setChatLoader(false);
        })
        .catch((error) => {
          // toast.error("Somthing went wrong", toastobj);
        });
      chatInputRef.current.value = "";
    }
  };
  useEffect(() => {
    const html = document.getElementsByTagName("html")[0];

    if (supportChatOpen && window.innerWidth <= 600) {
      html.classList.add("lock-scroll");
    } else {
      html.classList.remove("lock-scroll");
    }
    return () => {
      html.classList.remove("lock-scroll");
    };
  }, [supportChatOpen]);
  useEffect(() => {
    const getEventIdFromPath = (path) => {
      const match = path.match(/\/successful-gift-card\/(\w+)/);
      return match ? match[1] : null;
    };
    const isSuccessfulGiftCardPage = location.pathname.startsWith(
      "/successful-gift-card"
    );

    if (isSuccessfulGiftCardPage) {
      const eventId = getEventIdFromPath(location.pathname);

      // Your logic to handle the event ID
      if (eventId) {
        // console.log('Event ID:', eventId);
        setLoading(true);
        getGiftCardSuccessDetails(eventId)
          .then((response) => {
            setLoading(false);
            // console.log(response.data.Plans);
            if (response.data.status) {
              if (response.data.Card) {
                setSenderData(response.data.Card);
                toggleSuccessfullGiftCardModal();
              }
            }
          })
          .catch((error) => {
            toast.error("Somthing went wrong!", toastobj);
            setLoading(false);
          });
      }
    }
  }, [location.pathname]);

  const toggleComingSoonModal = () => {
    setComingSoonModal(!comingSoonModal);
  };

  const toggleReviewModal = () => {
    setReviewModalOpen(!reviewModalOpen);
    if (reviewOpen) {
      setReviewOpen(false);
    }
    resetFormReview();
  };

  useEffect(() => {
    if (reviewOpen && !isAuthenticated) toggleLogin();
    else if (reviewOpen && isAuthenticated) {
      toggleReviewModal();
    }
  }, [reviewOpen, isAuthenticated]);

  useEffect(() => {
    if (deleteRequest && !isAuthenticated) toggleLogin();
    else if (deleteRequest && isAuthenticated) toggleDeleteAccount();
  }, [deleteRequest, isAuthenticated]);

  const styles = {
    fontSize: "22px",
    ...(!darkMode && { color: "black" }),
  };
  return (
    <>
      <ThemeSetter />
      {loading && <Loader />}
      <div
        className={
          scroll
            ? subType && subType != "1"
              ? themeConfig[subType]?.backgroundClass
              : darkMode
                ? themeConfig[subType]?.backgroundClass.dark
                : themeConfig[subType]?.backgroundClass.light
            : null
        }
      >
        {subType && (
          <Navbar
            color="transparent"
            className={
              subType && scroll
                ? themeConfig[subType]?.scrollNavClass.true
                : themeConfig[subType]?.scrollNavClass.false
            }

          // className={scroll ? "main-header bg-dark" : "main-header"}
          >
            {/* <NavbarBrand href="/"> */}
            {/* <NavbarBrand href={subType?.["onLogoClick"]}>
            {subType === "1" ? (
              <img
                src={
                  darkMode
                    ? themeConfig[subType]?.logo?.dark
                    : themeConfig[subType]?.logo?.light
                }
                alt="logo"
              />
            ) : (
              <img src={themeConfig[subType]?.logo} alt="logo" />
            )}
          </NavbarBrand> */}
            <div
              className="navbar-brand"
              onClick={() => {
                if (isOpen) {
                  setIsOpen(false);
                }
              }}
            >
              <img
                src={
                  subType != "1"
                    ? themeConfig[subType]?.logo
                    : darkMode
                      ? themeConfig[subType]?.logo?.light
                      : themeConfig[subType]?.logo?.dark
                }
                alt="logo"
                style={{ cursor: "pointer", width: "100%", height: "100%" }}
                onClick={() => gotopage(themeConfig[subType]?.onLogoClick)}
              />
            </div>

            {!isAuthenticated ? (
              <></>
            ) : (
              <>
                <div
                  className={`${!isOpen && isAuthenticated ? "head-space-after-login" : ""
                    }`}
                ></div>
              </>
            )}

            {!isAuthenticated ? (
              <>
                <div className="top-login-signup menu-desktop-visible">
                  <NavItem
                    className={
                      subType != "1"
                        ? "head-login pointer"
                        : "head-login head-login-journal pointer"
                    }
                  >
                    <NavLink id="header-login-btn" onClick={toggleLogin}>
                      Login
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={
                      subType != "1"
                        ? "head-signup pointer"
                        : "head-signup head-signup-journal pointer"
                    }
                  >
                    <NavLink id="header-signup-btn" onClick={toggleSignup}>
                      Sign up
                    </NavLink>
                  </NavItem>
                </div>
              </>
            ) : (
              <>
                {isOpen && (
                  <div className="top-login-signup menu-desktop-visible">
                    <NavItem
                      className={
                        subType != "1"
                          ? "head-login pointer"
                          : "head-login head-login-journal pointer"
                      }
                    >
                      <NavLink id="header-logout-btn" onClick={handleLogout}>
                        Logout
                      </NavLink>
                    </NavItem>
                  </div>
                )}
              </>
            )}
            <div
              className="pointer"
              style={
                window.innerWidth > 600
                  ? { marginLeft: "5px", marginTop: "10px", zIndex: 1000 }
                  : { marginLeft: "5px", marginTop: "0px", zIndex: 1000 }
              }
              onClick={toggleSupportChat}
            >
              {supportChatOpen ? (
                <img
                  className="icon-36 "
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/support-chat-icon-green.png?t=2024-01-10T08%3A22%3A53.263Z"
                  alt=""
                  loading="lazy"
                />
              ) : (
                <img
                  className="icon-36 "
                  src={
                    darkMode
                      ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/support-chat-icon.png?t=2024-01-10T08%3A23%3A34.478Z"
                      : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/support_chat_black.png?t=2024-07-11T16%3A33%3A57.468Z"
                  }
                  alt=""
                  loading="lazy"
                />
              )}
            </div>

            <div
              style={
                subType == "1"
                  ? darkMode
                    ? {
                      background: "#050927",
                      borderRadius: "100%",
                      padding: "7px 0px",
                    }
                    : {
                      background: "white",
                      borderRadius: "100%",
                      padding: "7px 0px",
                    }
                  : null
              }
            >
              <NavbarToggler
                onClick={() => {
                  setIsOpen(!isOpen);
                  setSupportChatOpen(false);
                }}
                className={
                  !isOpen
                    ? subType != "1"
                      ? themeConfig.default.navTogglerTheme.open
                      : darkMode
                        ? themeConfig[subType]?.navTogglerTheme.open.dark
                        : themeConfig[subType]?.navTogglerTheme.open.light
                    : subType != "1"
                      ? themeConfig.default.navTogglerTheme.close
                      : darkMode
                        ? themeConfig[subType]?.navTogglerTheme.close.dark
                        : themeConfig[subType]?.navTogglerTheme.close.light
                }
              />
            </div>

            <Collapse
              isOpen={isOpen}
              navbar
              className={`justify-content-end ${darkMode ? "journal-collapse-dark" : "journal-collapse"
                }`}
            >
              <Nav className="mr-auto" navbar>
                {!isAuthenticated ? (
                  <div
                    className={
                      window.innerWidth > 600
                        ? "nav-item top-login-signup menu-mobile-visible"
                        : "nav-item top-login-signup menu-mobile-visible flexRowCenter"
                    }
                  >
                    <NavItem className="head-login">
                      <NavLink onClick={toggleLogin}>Login</NavLink>
                    </NavItem>
                    <NavItem className="head-signup">
                      <NavLink onClick={toggleSignup}>Sign up</NavLink>
                    </NavItem>
                  </div>
                ) : (
                  <>
                    <NavItem className="head-signup">
                      <NavLink onClick={handleLogout}>Logout</NavLink>
                    </NavItem>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      className="dropdown top-after-login"
                    >
                      <DropdownToggle nav caret className="dropbtn">
                        {showname}
                        <KeyboardArrowDownRoundedIcon className="menu-arrow-icon" />
                      </DropdownToggle>
                      <DropdownMenu
                        end
                        className="dropdown-content"
                        style={{ right: 0 }}
                      >
                        <DropdownItem
                          onClick={() => gotopage("auth/additionalDetails")}
                        >
                          <div className=" primary-hover">My Information</div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => gotopage("auth/account_info")}
                        >
                          <div className=" primary-hover">
                            Billing and payments
                          </div>
                        </DropdownItem>

                        {/* <DropdownItem
                      onClick={() => gotopage("build_your_experience")}
                    >
                      <div>Build Your Experience</div>
                    </DropdownItem> */}
                        <DropdownItem
                          onClick={() => gotopage("auth/account_subscription")}
                        >
                          <div className=" primary-hover">Subscription</div>
                        </DropdownItem>
                        <DropdownItem onClick={() => gotopage("auth/settings")}>
                          <div className=" primary-hover">
                            Policies & Settings
                          </div>
                        </DropdownItem>
                        <DropdownItem onClick={toggleDeleteAccount}>
                          <div className=" primary-hover">Delete Account</div>
                        </DropdownItem>

                        {/*  <DropdownItem onClick={handleLogout}>
                          <div className="">LogOut</div>
                        </DropdownItem> */}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}

                <NavItem
                  className={isActive("/")}
                  style={{ borderBottom: "none" }}
                >
                  <NavLink href="/">Home</NavLink>
                </NavItem>
                <NavItem className={isActive("/create_subscription")}>
                  <NavLink href="/create_subscription">Pricing Plans</NavLink>
                </NavItem>

                {subType == "3" ? (
                  <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle nav caret style={{ color: "#8850ff" }}>
                      <div className="flexRowStart al-center jc-spb">
                        <div>
                          <img
                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon1.svg"
                            className="sideMenuIcon"
                            alt=""
                            loading="lazy"
                          />

                          <span className="header-span-el">
                            Seventh<b>SIGHT</b> — Dream Analysis
                          </span>
                        </div>
                        <KeyboardArrowDownRoundedIcon />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => gotopage("analyze")}>
                        Analyze New Dream
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => gotopage("auth/previous_dreams")}
                      >
                        <div>Previous Dreams</div>
                      </DropdownItem>
                      {/* <DropdownItem>Dream insights</DropdownItem> */}
                      {/* <DropdownItem onClick={() => gotopage('dream-assitant')}> */}

                      <DropdownItem
                        onClick={() => gotopage("dreamology-arcana")}
                      >
                        Your Dream Arcana
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => gotopage("auth/dream-statistics")}
                      >
                        Additional Details
                      </DropdownItem>

                      <DropdownItem onClick={() => gotopage("dream-stories")}>
                        Dream Stories
                      </DropdownItem>
                      {/* <DropdownItem
										onClick={() => gotopage('instructional-videos')} */}
                      {/* <DropdownItem
										onClick={() => gotopage('new-instructional-videos')}
									>
										Instructional videos{' '}
									</DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <NavItem>
                    <NavLink href="/">
                      <img
                        src={
                          darkMode
                            ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon1.svg"
                            : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon1-black.svg"
                        }
                        className="sideMenuIcon"
                        alt=""
                        loading="lazy"
                      />{" "}
                      SeventhSIGHT-Dream Analysis
                    </NavLink>
                  </NavItem>
                )}

                {subType == "1" ? (
                  <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle
                      nav
                      caret
                      style={{
                        color: "#8850ff",
                      }}
                    >
                      <div className="flexRowStart al-center jc-spb">
                        <div>
                          <img
                            src={
                              darkMode
                                ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon4.svg?t=2024-01-10T08%3A04%3A28.825Z"
                                : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon4-black.svg?t=2024-01-10T07%3A35%3A11.594Z"
                            }
                            className="sideMenuIcon"
                            alt=""
                            loading="lazy"
                          />

                          <span className="header-span-el">
                            Seventh<b>JOURNAL</b> — Daily Diary
                          </span>
                        </div>
                        <KeyboardArrowDownRoundedIcon />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="journal-drop-down-menu">
                      <DropdownItem
                        onClick={() => gotopage("auth/addjournalentry")}
                      >
                        Add New Entry
                      </DropdownItem>
                      <DropdownItem onClick={() => gotopage("auth/myjournal")}>
                        My Journal
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => gotopage("auth/journalsummary")}
                      >
                        Summary, Stats & Insights
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => gotopage("auth/addjournalentry")}
                      >
                        Gratitude Poems
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => gotopage("auth/journalgoodhabit")}
                      >
                        Good Habbit Log
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => gotopage("auth/journalgoals")}
                      >
                        Goals & Resources
                      </DropdownItem>
                      <DropdownItem onClick={toggleComingSoonModal}>
                        Print Your Diary
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => gotopage("auth/journalsetting")}
                      >
                        Journal Settings
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <NavItem className={isActive("/SeventhJournal")}>
                    <NavLink href="/seventhjournal">
                      <img
                        src={
                          darkMode
                            ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon4.svg?t=2024-01-10T08%3A04%3A28.825Z"
                            : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon4-black.svg?t=2024-01-10T07%3A35%3A11.594Z"
                        }
                        className="sideMenuIcon"
                        alt=""
                        loading="lazy"
                      />
                      Seventh<b>JOURNAL</b> — Daily Diary
                    </NavLink>
                  </NavItem>
                )}
                {subType == "2" ? (
                  <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle nav caret style={{ color: "#8850ff" }}>
                      <div className="flexRowStart al-center jc-spb">
                        <div>
                          <img
                            src={
                              darkMode
                                ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon2.svg"
                                : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon2-black.svg?t=2024-01-10T07%3A26%3A57.382Z"
                            }
                            className="sideMenuIcon"
                            alt=""
                            loading="lazy"
                          />

                          <span className="header-span-el">
                            Seventh<b>ARC</b> — Personality Test
                          </span>
                        </div>
                        <KeyboardArrowDownRoundedIcon />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => gotopage("auth/arcana_persona")}
                      >
                        Personality test
                      </DropdownItem>
                      <DropdownItem>My archetype</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <NavItem className={isActive("/seventharc")}>
                    <NavLink
                      href={
                        showSeventhArc
                          ? "/seventharc"
                          : "/coming-soon-new?scrollTo=seventhArcContainer"
                      }
                    >
                      <img
                        src={
                          darkMode
                            ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon2.svg"
                            : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon2-black.svg?t=2024-01-10T07%3A26%3A57.382Z"
                        }
                        className="sideMenuIcon"
                        alt=""
                        loading="lazy"
                      />
                      Seventh<b>ARC</b> — Personality Test
                    </NavLink>
                  </NavItem>
                )}

                {/* {showSeventhArc ? (
                  <NavItem className={isActive("/seventharc")}>
                    <NavLink href="/seventharc">
                      <img
                        src={darkMode ? sideMenuIcon2Light : sideMenuIcon2}
                        className="sideMenuIcon"
                        alt=""
                      />
                      Seventh<b>ARC</b> — Personality Test
                    </NavLink>
                  </NavItem>
                ) : (
                  <NavItem className={isActive("/seventharc")}>
                    <NavLink href="/coming-soon-new">
                      <img
                        src={darkMode ? sideMenuIcon2Light : sideMenuIcon2}
                        className="sideMenuIcon"
                        alt=""
                      />
                      Seventh<b>ARC</b> — Personality Test
                    </NavLink>
                  </NavItem>
                )} */}

                <NavItem className={isActive("/SeventhCare")}>
                  {/* <NavLink href="/seventhcare"> */}
                  <NavLink href="/coming-soon-new?scrollTo=seventhCareContainer">
                    <img
                      src={
                        darkMode
                          ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon5.svg?t=2024-01-10T08%3A07%3A12.238Z"
                          : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon5-black.svg?t=2024-01-10T08%3A05%3A32.120Z"
                      }
                      className="sideMenuIcon"
                      alt=""
                      loading="lazy"
                    />
                    Seventh<b>CARE</b> — Counselor
                  </NavLink>
                </NavItem>
                <NavItem className={isActive("/seventhguide")}>
                  {/* <NavLink href="/seventhguide"> */}
                  <NavLink href="/coming-soon-new?scrollTo=seventhGuideContainer">
                    <img
                      src={
                        darkMode
                          ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon3.svg"
                          : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon3-black.svg?t=2024-01-10T07%3A32%3A36.801Z"
                      }
                      className="sideMenuIcon"
                      alt=""
                      loading="lazy"
                    />
                    Seventh<b>GUIDE</b> — Meditation
                  </NavLink>
                </NavItem>
                <NavItem className={isActive("/VideoSeries")}>
                  <NavLink href="https://betweentwoworlds.tv/" target="_blank">
                    <img
                      src={
                        darkMode
                          ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon6.svg?t=2024-01-10T08%3A09%3A45.909Z"
                          : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/side-menu-icon6-black.svg?t=2024-01-10T08%3A08%3A24.909Z"
                      }
                      className="sideMenuIcon"
                      alt=""
                      loading="lazy"
                    />
                    Between Two Worlds Video Series
                  </NavLink>
                </NavItem>
                <NavItem className={isActive("/contactus")}>
                  <NavLink href="/contactus">Contact Us</NavLink>
                </NavItem>
                {/* <NavItem className="pointer" onClick={togglefeedback}>
								<NavLink>Contact Us</NavLink>
							</NavItem> */}
                <NavItem className="pointer" onClick={togglefeedback}>
                  <NavLink>Page Feedback</NavLink>
                </NavItem>
                <NavItem className={isActive("/blog")}>
                  <NavLink href={`${website_url}/blog/`}>
                    Articles & Blog
                  </NavLink>
                </NavItem>
                <NavItem className={isActive("/buy-gift-card")}>
                  <NavLink href="/buy-gift-card">Gift Cards</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        )}

        {subType && (
          <Modal
            isOpen={modalLogin}
            toggle={toggleLogin}
            className={`login-seventh journal-login-modal ${darkMode
              ? "journal-dark-button-theme"
              : "journal-light-button-theme"
              }`}
            backdrop="static"
          >
            {!isAuthenticated ? (
              <>
                <ModalHeader toggle={toggleLogin}>Login</ModalHeader>
                <ModalBody>
                  <div>
                    <Form onSubmit={handleSubmitLogin}>
                      <FormGroup className="position-relative">
                        <Label for="exampleEmail">Email</Label>
                        <Input
                          id="exampleEmail"
                          name="email"
                          placeholder="Email Address"
                          type="email"
                          value={lemail}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="position-relative">
                        <Label for="examplePassword">Password</Label>

                        <InputGroup>
                          <Input
                            id="examplePassword"
                            name="password"
                            placeholder="***********"
                            type={showPassword ? "text" : "password"}
                            value={lpassword}
                            onChange={(e) => setPassword(e.target.value)}
                            className="remove-default-icon"
                          />

                          <InputGroupText
                            className="input-bg-pass"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroupText>
                        </InputGroup>
                      </FormGroup>

                      <FormGroup check>
                        <Row>
                          <Col>
                            <Input
                              type="checkbox"
                              style={{ width: "1.25rem" }}
                            />{" "}
                            <Label check>Remember me</Label>
                          </Col>

                          <Col className="text-right">
                            <span
                              style={{ color: "#8850ff", cursor: "pointer" }}
                              onClick={toggleForgotPass}
                            >
                              Forgot Password?
                            </span>
                          </Col>
                        </Row>
                      </FormGroup>
                      <Button
                        id="modal-login-btn"
                        className="w-100 btn-primary mt-4"
                      >
                        Login
                      </Button>
                    </Form>

                    <div className="login-social-icon mt-4">
                      <img
                        onClick={handlegooglesignup}
                        src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/google-icon.svg?t=2024-01-10T04%3A00%3A21.459Z"
                        style={{ cursor: "pointer" }}
                        alt="Google"
                        loading="lazy"
                      />{" "}
                      {/* <img
                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/linkedin-icon.svg?t=2024-01-10T06%3A43%3A49.707Z"
                      alt=""
                    />{" "}
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={handlefacebooksignup}
                      alt="Facebook"
                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/facebook-icon.svg?t=2024-01-09T18%3A07%3A06.976Z"
                    />{" "} */}
                      <img
                        src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/apple-icon.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={handleapplesignup}
                        loading="lazy"
                      />
                    </div>

                    <div className="mt-4">
                      <p className="login-descp1">
                        Don't have an account?{" "}
                        <span
                          id="modal-signup-span-btn"
                          style={{ color: "#8850ff", cursor: "pointer" }}
                          onClick={() => {
                            toggleLogin();
                            setTimeout(() => {
                              toggleSignup();
                            }, 500);
                          }}
                        >
                          Signup
                        </span>
                        <br />
                        <span className="login-descp2">
                          I just want to check a dream as a{" "}
                          <span
                            style={{ color: "#8850ff", cursor: "pointer" }}
                            onClick={() => {
                              toggleLogin();
                              history("/analyze");
                            }}
                            id="modal-guest-span-btn"
                          >
                            Guest
                          </span>
                        </span>
                        <br />
                        <span className="login-descp2">
                          Need assistance?{" "}
                          <span
                            style={{ color: "#8850ff", cursor: "pointer" }}
                            onClick={() => {
                              window.open(`${website_url}/contactus`, "_blank");
                            }}
                            id="modal-guest-span-btn"
                          >
                            ContactUs
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </ModalBody>
              </>
            ) : (
              <ModalBody>
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className=""
                >
                  Thank you! You have successfully logged in!
                </div>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={toggleLogin}
                ></button>
              </ModalBody>
            )}
          </Modal>
        )}

        <Modal
          isOpen={modalSignup}
          toggle={toggleSignup}
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
          backdrop="static"
        >
          <ModalHeader toggle={toggleSignup}>Signup</ModalHeader>
          <ModalBody>
            <div>
              {/* <Form onSubmit={handleSignupSubmit}> */}
              <form onSubmit={handleSubmit} autoComplete="off">
                <FormGroup className="position-relative">
                  <Label>Email Address</Label>
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.email && !errors.email}
                    invalid={touched.email && !!errors.email}
                  />

                  {touched.email ? (
                    <FormFeedback>{errors.email}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label>User Name</Label>
                  <Input
                    name="username"
                    placeholder="User Name"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.username && !errors.username}
                    invalid={touched.username && !!errors.username}
                  />
                  {touched.username ? (
                    <FormFeedback>{errors.username}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label>Password</Label>
                  <InputGroup>
                    <Input
                      name="password"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      valid={touched.password && !errors.password}
                      invalid={touched.password && !!errors.password}
                    />
                    <InputGroupText
                      className="input-bg-pass"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroup>
                  {touched.password ? (
                    <FormFeedback>{errors.password}</FormFeedback>
                  ) : null}
                </FormGroup>

                <FormGroup className="position-relative">
                  <Label>Confirm Password</Label>
                  <InputGroup>
                    <Input
                      name="confirmpassword"
                      placeholder="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={values.confirmpassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      valid={touched.confirmpassword && !errors.confirmpassword}
                      invalid={
                        touched.confirmpassword && !!errors.confirmpassword
                      }
                    />
                    <InputGroupText
                      className="input-bg-pass"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroup>
                  {touched.confirmpassword ? (
                    <FormFeedback>{errors.confirmpassword}</FormFeedback>
                  ) : null}
                </FormGroup>
                {captchaKey && (
                  <FormGroup className="position-relative">
                    <Label>Captcha</Label>
                    <ReCAPTCHA
                      // name="recaptcha"
                      sitekey={captchaKey}
                      // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      render="explicit"
                      theme="dark"
                      onChange={(e) => {
                        // console.log(e);
                        setFieldValue("recaptcha", e);
                      }}
                    /* verifyCallback={(response) => {
                    setFieldValue("recaptcha", response);
                  }}
                  onloadCallback={() => {
                    console.log("done loading!");
                  }} */
                    />
                    {touched.recaptcha && errors.recaptcha ? (
                      <div className="text-danger">{errors.recaptcha}</div>
                    ) : null}
                  </FormGroup>
                )}

                <div className="flexRowStart">
                  <Input
                    id="newsletterid"
                    name="newsLetter"
                    type="checkbox"
                    value={values.newsLetter}
                    defaultChecked={true}
                    onChange={handleChange}
                    style={{ width: "1.75rem" }}
                  />{" "}
                  <Label check htmlFor="newsletterid" className="pointer">
                    I would like to receive insights, interesting facts about
                    dreams and occasional promotions.
                  </Label>
                </div>

                <Button
                  id="modal-signup-btn"
                  type="submit"
                  className="w-100 btn-primary mt-4"
                >
                  Signup
                </Button>
              </form>

              <div className="login-social-icon mt-4">
                <img
                  onClick={handlegooglesignup}
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/google-icon.svg?t=2024-01-10T04%3A00%3A21.459Z"
                  style={{ cursor: "pointer" }}
                  alt="Google"
                  loading="lazy"
                />{" "}
                {/* <img
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/linkedin-icon.svg?t=2024-01-10T06%3A43%3A49.707Z"
                  alt=""
                />{" "}
                <img
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/facebook-icon.svg?t=2024-01-09T18%3A07%3A06.976Z"
                  alt=""
                />{" "} */}
                <img
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/apple-icon.svg"
                  alt=""
                  onClick={handleapplesignup}
                  loading="lazy"
                />
              </div>

              <div className="mt-4">
                <p className="login-descp1">
                  Already have an account?{" "}
                  <span
                    style={{ color: "#8850ff", cursor: "pointer" }}
                    onClick={() => {
                      toggleSignup();
                      setTimeout(() => {
                        toggleLogin();
                      }, 500);
                    }}
                  >
                    Login
                  </span>
                </p>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modalForgotPass}
          toggle={toggleForgotPass}
          style={
            window.innerWidth > 600
              ? {
                // position: 'absolute',
                top: "50%",
                transform: "translateY(-50%)",
              }
              : {
                top: "0%",
                transform: "translateY(0%)",
              }
          }
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
          backdrop="static"
        >
          <ModalHeader toggle={toggleForgotPass}>Password Reset</ModalHeader>
          <ModalBody>
            <div>
              <Form onSubmit={handleSubmitForgotPass}>
                <FormGroup className="position-relative">
                  <Label for="exampleEmail">Email</Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    value={lemail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <Button className="w-100 btn-primary mt-4">
                  Send reset link
                </Button>
              </Form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showForgotPassEmailPopup}
          toggle={toggleShowForgotPassEmailPopup}
          backdrop="static"
          style={
            window.innerWidth > 600
              ? {
                // position: 'absolute',
                top: "50%",
                transform: "translateY(-50%)",
              }
              : {
                top: "0%",
                transform: "translateY(0%)",
              }
          }
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
        >
          <ModalHeader toggle={toggleShowForgotPassEmailPopup}>
            Password Reset
          </ModalHeader>
          <ModalBody>
            <div className="flexColCenter jc-center rg-1">
              <p style={{ textAlign: "center" }} className="color-black">
                We have sent a letter with password reset link to email &nbsp;
                {lemail}
              </p>
              <p style={{ textAlign: "center" }} className="color-black">
                Please follow instructions from the letter.
              </p>
              <p style={{ textAlign: "center" }} className="color-black">
                Didn’t receive it?{" "}
                {resendVisible ? (
                  <span
                    className="text-primary pointer "
                    style={{ fontWeight: "bold" }}
                    onClick={handleSubmitForgotPass}
                  >
                    {" "}
                    Resend the letter
                  </span>
                ) : (
                  <p className="color-black">
                    You can retry in
                    <span className="text-primary pointer ">
                      {" "}
                      ({`00:${timer < 10 ? `0${timer}` : timer}`})
                    </span>
                  </p>
                )}
              </p>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modalNewPass}
          toggle={toggleNewPass}
          style={
            window.innerWidth > 600
              ? {
                // position: 'absolute',
                top: "50%",
                transform: "translateY(-50%)",
              }
              : {
                top: "0%",
                transform: "translateY(0%)",
              }
          }
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
        >
          <ModalHeader toggle={toggleNewPass}>Set New Password</ModalHeader>
          <ModalBody>
            <div>
              <Form onSubmit={resetNewPassword}>
                <FormGroup className="position-relative">
                  <Label for="examplePassword">Enter new password</Label>

                  <InputGroup>
                    <Input
                      name="new_password"
                      placeholder="***********"
                      type={showPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <InputGroupText
                      className="input-bg-pass"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label for="examplePassword">Repeat new password</Label>

                  <InputGroup>
                    <Input
                      name="confirm_new_password"
                      placeholder="***********"
                      type={showConfirmPassword ? "text" : "password"}
                      vvalue={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <InputGroupText
                      className="input-bg-pass"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>

                <Button className="w-100 btn-primary mt-4">
                  Set new password
                </Button>
              </Form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={modalPasswordSet}
          toggle={togglePasswordSet}
          style={
            window.innerWidth > 600
              ? {
                // position: 'absolute',
                top: "50%",
                transform: "translateY(-50%)",
              }
              : {
                top: "0%",
                transform: "translateY(0%)",
              }
          }
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
        >
          <div className="flexColCenter al-center ">
            <img
              className="icon-48"
              style={{ flex: 1, paddingTop: "50px" }}
              src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/CheckedImage.png?t=2024-01-09T11%3A07%3A29.460Z"
              alt=""
              loading="lazy"
            />
          </div>
          <ModalHeader toggle={togglePasswordSet}>
            New Password Has Been Set
          </ModalHeader>
        </Modal>
        <Modal
          isOpen={emailAlreadyRegisteredModal}
          toggle={toggleEmailAlreadyRegistered}
          style={
            window.innerWidth > 600
              ? {
                // position: 'absolute',
                top: "50%",
                transform: "translateY(-50%)",
              }
              : {
                top: "0%",
                transform: "translateY(0%)",
              }
          }
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
        >
          <ModalHeader toggle={toggleEmailAlreadyRegistered}>
            Email Already Registered
          </ModalHeader>
          <ModalBody style={{ paddingTop: "0px" }}>
            <p style={{ textAlign: "center" }}>
              There's already an account associated with this email address.
              Please{" "}
              <span
                className="text-primary pointer"
                onClick={() => {
                  toggleEmailAlreadyRegistered();
                  toggleLogin();
                }}
              >
                Log in{" "}
              </span>{" "}
              or&nbsp;
              <span
                className="text-primary pointer"
                onClick={() => {
                  toggleEmailAlreadyRegistered();
                  toggleForgotPass();
                }}
              >
                Reset your password
              </span>{" "}
              if you've forgotten it.
            </p>
            <p style={{ textAlign: "center" }}>
              If you believe this is an error, feel free to
              <span
                className="text-primary pointer"
                onClick={() => {
                  toggleEmailAlreadyRegistered();
                  history("/contactus");
                }}
              >
                &nbsp;Contact
              </span>{" "}
              our support team for assistance.
            </p>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={newSignInModal}
          // isOpen={true}
          toggle={toggleNewSignInModal}
          style={
            window.innerWidth > 600
              ? {
                // position: 'absolute',
                top: "50%",
                transform: "translateY(-50%)",
              }
              : {
                top: "0%",
                transform: "translateY(0%)",
              }
          }
          backdrop="static"
          keyboard={false}
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
        >
          <div className="flexColCenter al-center ">
            <img
              // className="icon-48"
              style={
                window.innerWidth > 600
                  ? { paddingTop: "50px", maxWidth: "450px" }
                  : { paddingTop: "20px", width: "250px" }
              }
              src={
                window.innerWidth > 600
                  ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/email_confirmation_img.png?t=2024-01-09T15%3A16%3A24.809Z"
                  : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/email_confirmation_img_bg.png"
              }
              alt=""
              loading="lazy"
            />
          </div>
          <ModalHeader
            toggle={toggleNewSignInModal}
            style={{ paddingBottom: "0px" }}
          >
            {window.innerWidth > 600 ? (
              <h4 style={{ marginBottom: "0px" }}>
                thank you, Your email has been successfully confirmed
              </h4>
            ) : (
              <h3 style={{ marginBottom: "0px" }}>
                thank you, Your email has been successfully confirmed
              </h3>
            )}
          </ModalHeader>
          <ModalBody style={{ padding: "0px 0px 16px 0px" }}>
            <p style={{ textAlign: "center" }}>
              {/* <p> */}
              Congratulations on joining the community! Your journey towards
              understanding your dreams and unlocking self-awareness starts
              here. Welcome aboard to a world of insights and personal growth!
            </p>
            <div className="w-100 textCenter">
              <Button
                type="button"
                color="primary"
                onClick={toggleNewSignInModal}
              >
                Continue!
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {showPopup && (
          <Modal
            isOpen={true}
            toggle={toggleModalSignupSentMail}
            style={
              window.innerWidth > 600
                ? {
                  // position: 'absolute',
                  top: "50%",
                  transform: "translateY(-50%)",
                }
                : {
                  top: "0%",
                  transform: "translateY(0%)",
                }
            }
            className={`login-seventh journal-login-modal ${darkMode
              ? "journal-dark-button-theme"
              : "journal-light-button-theme"
              }`}
          >
            {/* <div className="flexColCenter al-center ">
              <img
                className="icon-48"
                style={{ flex: 1, paddingTop: "30px" }}
                src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/CheckedImage.png?t=2024-01-09T11%3A07%3A29.460Z"
                alt=""
              />
            </div> */}

            <Lottie
              className="flexColCenter al-center lottie-signup-confirm w-100"
              animationData={LottieData}
              loop={true}
            />

            <ModalHeader
              toggle={toggleModalSignupSentMail}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              Check your email!
            </ModalHeader>
            <ModalBody style={{ paddingTop: "0px" }}>
              <p style={{ textAlign: "center" }}>
                The letter has been sent to your email address. Please follow
                instructions from the letter.
              </p>
            </ModalBody>
          </Modal>
        )}

        <Modal
          isOpen={giftCardSuccessModal}
          toggle={toggleSuccessfullGiftCardModal}
          style={
            window.innerWidth > 600
              ? {
                // position: 'absolute',
                top: "50%",
                transform: "translateY(-50%)",
              }
              : {
                top: "0%",
                transform: "translateY(0%)",
              }
          }
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
        >
          <div className="flexColCenter al-center ">
            <img
              className="icon-48"
              style={{ flex: 1, paddingTop: "30px" }}
              src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/CheckedImage.png?t=2024-01-09T11%3A07%3A29.460Z"
              alt=""
              loading="lazy"
            />
          </div>
          <ModalHeader
            toggle={toggleSuccessfullGiftCardModal}
            style={{ paddingTop: "0px", paddingBottom: "0px" }}
          >
            Thank you
          </ModalHeader>
          <ModalBody style={{ paddingTop: "0px" }}>
            <p style={{ textAlign: "center" }}>
              We received your payment, and your Gift Card was successfully
              registered.
            </p>
            <p>
              <strong>Recipent:</strong> {senderData?.recipient_email}
              <br />
              <strong>Deliver on:</strong>{" "}
              {senderData?.gift_card_recipt_date.split("T")[0]}
            </p>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={reviewModalOpen}
          toggle={toggleReviewModal}
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
          backdrop="static"
        >
          <ModalHeader toggle={toggleReviewModal}>Add Review</ModalHeader>
          <ModalBody>
            <div>
              <form onSubmit={handleSubmitNew} autoComplete="off">
                <FormGroup className="position-relative">
                  <div className="flexRowStart cg-02">
                    <Label>Rating</Label>
                    <span style={{ color: "red" }}>*</span>
                  </div>

                  <ReactStars
                    value={valuesReview.rating}
                    count={5}
                    onChange={(rating) => setFieldValueReview("rating", rating)}
                    size={48}
                    emptyIcon={<i className="far fa-star"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#ffd700"
                    style={{ display: "block", margin: "auto" }}
                  />
                  {errosReview.rating ? (
                    <span className="text-danger">{errosReview.rating}</span>
                  ) : null}
                </FormGroup>
                <FormGroup className="position-relative">
                  <div className="flexRowStart cg-02">
                    <Label>Name</Label>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <Input
                    name="username"
                    placeholder="Name"
                    value={valuesReview.username}
                    onChange={handleChangeReview}
                    onBlur={handleBlurReview}
                    valid={touchedReview.username && !errosReview.username}
                    invalid={touchedReview.username && !!errosReview.username}
                  />
                  {touchedReview.username ? (
                    <span className="text-danger">{errosReview.username}</span>
                  ) : null}
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label>Review</Label>
                  <Input
                    name="review"
                    placeholder="Add Review"
                    value={valuesReview.review}
                    onChange={handleChangeReview}
                    onBlur={handleBlurReview}
                    valid={touchedReview.review && !errosReview.review}
                    invalid={touchedReview.review && !!errosReview.review}
                    type="textarea"
                    className="textarea-lg"
                  />
                  {touchedReview.review ? (
                    <span className="text-danger">{errosReview.review}</span>
                  ) : null}
                </FormGroup>

                {captchaKey && (
                  <FormGroup className="position-relative">
                    <div className="flexRowStart cg-02">
                      <Label>Captcha</Label>
                      <span style={{ color: "red" }}>*</span>
                    </div>

                    <ReCAPTCHA
                      sitekey={captchaKey}
                      // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      render="explicit"
                      theme="dark"
                      onChange={(e) => {
                        // console.log(e);
                        setFieldValueReview("recaptcha", e);
                      }}
                    />
                    {touchedReview.recaptcha && errosReview.recaptcha ? (
                      <div className="text-danger">{errosReview.recaptcha}</div>
                    ) : null}
                  </FormGroup>
                )}

                <Button className="w-100 btn-primary mt-4">Submit</Button>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={deleteModal}
          toggle={toggleDeleteAccount}
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
          backdrop="static"
        >
          <ModalHeader toggle={toggleDeleteAccount}>Delete Account</ModalHeader>
          <ModalBody>
            <div>
              <form onSubmit={handleSubDelete} autoComplete="off">
                <FormGroup className="position-relative">
                  <Label>Name</Label>
                  <Input
                    name="username"
                    placeholder="Name"
                    value={valuesDelete.username}
                    onChange={handleChangeDelete}
                    onBlur={handleBlurDelete}
                    valid={touchedDelete.username && !errorsDelete.username}
                    invalid={touchedDelete.username && !!errorsDelete.username}
                  />
                  {touchedDelete.username ? (
                    <FormFeedback>{errorsDelete.username}</FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label>Email</Label>
                  <Input
                    name="email"
                    placeholder="Email"
                    value={valuesDelete.email}
                    onChange={handleChangeDelete}
                    onBlur={handleBlurDelete}
                    valid={touchedDelete.email && !errorsDelete.email}
                    invalid={touchedDelete.email && !!errorsDelete.email}
                    type="email"
                    disabled={true}
                  />
                  {touchedDelete.email ? (
                    <FormFeedback>{errorsDelete.email}</FormFeedback>
                  ) : null}
                </FormGroup>

                {captchaKey && (
                  <FormGroup className="position-relative">
                    <Label>Captcha</Label>
                    <ReCAPTCHA
                      sitekey={captchaKey}
                      // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      render="explicit"
                      theme={darkMode ? "dark" : "light"}
                      onChange={(e) => {
                        // console.log(e);
                        setFieldValueDelete("recaptcha", e);
                      }}
                    />
                    {touchedDelete.recaptcha && errorsDelete.recaptcha ? (
                      <div className="text-danger">
                        {errorsDelete.recaptcha}
                      </div>
                    ) : null}
                  </FormGroup>
                )}

                <Button className="w-100 btn-primary mt-4">Submit</Button>
              </form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={deleteWarningModal}
          toggle={toggleDeleteWarningModal}
          backdrop="static"
          className={`login-seventh journal-login-modal ${darkMode
            ? "journal-dark-button-theme"
            : "journal-light-button-theme"
            }`}
        >
          <ModalHeader toggle={() => toggleDeleteWarningModal()}></ModalHeader>
          <ModalBody>
            <div className="text-center payment-done-modal analyze-form ">
              <div>
                <div>
                  <InfoIcon
                    className="payment-done-checkicon"
                    style={{ color: "red" }}
                  />
                </div>
                {/* <h3>Info</h3> */}

                <p style={styles}>
                  Your account is scheduled for deletion. Confirm your decision
                  by clicking the button below. Once deleted, all data will be
                  lost permanently.
                </p>

                <div className="d-flex justify-content-center">
                  <Button
                    className="btn-primary"
                    onClick={() => {
                      handleDeleteAccount(valuesDelete);
                    }}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {supportChatOpen && (
          <div className="support-chat-container">
            <div className="support-chat-hello-container flexRowStart jc-spb al-center">
              <div className="flexRowStart ">
                <img
                  style={{ width: "50px", height: "50px" }}
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/support-chat-bot-img.png?t=2024-01-10T08%3A21%3A15.337Z"
                  alt=""
                  loading="lazy"
                />
                <div className="flexColStart">
                  <span
                    className="sub-heading-1"
                    style={{ color: darkMode ? "white" : "black" }}
                  >
                    Hello!
                  </span>
                  <span style={{ color: darkMode ? "white" : "black" }}>
                    We are here to help
                  </span>
                </div>
              </div>
              <img
                className="pointer"
                src={
                  darkMode
                    ? "https://db.seventhsight.com/storage/v1/object/public/Website_Images/mobile-menubar-white-close.png"
                    : "https://db.seventhsight.com/storage/v1/object/public/Website_Images/seventh-login-close.png?t=2024-01-10T07%3A09%3A08.512Z"
                }
                alt="alt"
                onClick={toggleSupportChat}
                loading="lazy"
              />
            </div>
            <div
              className="support-chat-message-container"
            // style={{ overflowY: 'scroll' }}
            >
              <ul
                className="chatbox-chats chatbox-chats-support"
                ref={chatboxRef}
                style={{
                  listStyleType: "none",
                  overflowY: "auto",
                  overflowX: "none",
                }}
              >
                <div className="clearfix"></div>
                {chatLoader && <ChatLoader supportChat={true} />}
              </ul>

              {chatLoader ? (
                <></>
              ) : (
                <>
                  <div className="chatbox-notification">
                    <Row>
                      <Col className="col-9 col-md-10">
                        <input
                          ref={chatInputRef}
                          // value={chatinputans}
                          // onKeyDown={handleenterkey}
                          // onChange={(e) => setchatinputans(e.target.value)}
                          placeholder="Type here..."
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleChat();
                            }
                          }}
                          style={{ color: darkMode ? "white" : "black" }}
                        />
                      </Col>
                      <Col className="col-3 col-md-2">
                        <Button
                          // onClick={() => handlechatanswer(chatinputans)}
                          onClick={handleChat}
                        >
                          <img
                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/chat-icon/chat-icon-send.png?t=2024-01-09T08%3A33%3A26.283Z"
                            alt=""
                            loading="lazy"
                          />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {/* <div ref={messagesEndRef} /> */}
            </div>
          </div>
        )}
        {comingSoonModal && (
          <div className="mod-wrapper">
            <div className="mod-container ">
              <div className="mod-header">
                <FontAwesomeIcon
                  icon={faXmark}
                  size="2xl"
                  alt="img"
                  className="mod-close-btn"
                  onClick={() => toggleComingSoonModal()}
                />
              </div>
              <div className="flexColCenter rg-1 mod-header-settings-backup">
                <img
                  className="icon-48"
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/CheckedImage.png?t=2024-01-09T11%3A07%3A29.460Z"
                  alt=""
                  loading="lazy"
                />
                <h2
                  className="textCenter"
                  style={{
                    color: "var(--text-color-black)",
                    display: "block",
                    minWidth: "300px",
                  }}
                >
                  COMING SOON
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
